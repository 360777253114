import React, { useRef, useState } from 'react';
import HomeFooter from "../Footer/Footer";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Col, Row } from "antd";
import { css } from "@emotion/css";
import { Alert } from "antd";
import Marquee from "react-fast-marquee";
import CarouselCard from "./Component/carouselCard";
import ContentMenu from "./Component/contentMenu";
import HomeHeaderNav from "../Header/Header";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import {
	FreeMode,
	Navigation,
	Thumbs,
	Autoplay,
	Pagination,
} from "swiper/modules";



import classNames from "classnames";

const Singin = "https://fs.cdnxn.com/landingQueen/Images/button-sigin.png";
const Login = "https://fs.cdnxn.com/landingQueen/Images/button-login.png";
const BtnClose = "https://fs.cdnxn.com/landingQueen/Images/Icon-Close.png";
const menuGameHot = "https://fs.cdnxn.com/landingQueen/Images/text-gamehot.png";
const menuLookAll = "https://fs.cdnxn.com/landingQueen/Images/text-lookall.png";
const Game1 = "https://fs.cdnxn.com/landingQueen/Images/Game/game1.png";
const Game2 = "https://fs.cdnxn.com/landingQueen/Images/Game/game2.png";
const Game3 = "https://fs.cdnxn.com/landingQueen/Images/Game/game3.png";
const Game4 = "https://fs.cdnxn.com/landingQueen/Images/Game/game4.png";
const Game5 = "https://fs.cdnxn.com/landingQueen/Images/Game/game5.png";
const Game6 = "https://fs.cdnxn.com/landingQueen/Images/Game/game6.png";
const Game7 = "https://fs.cdnxn.com/landingQueen/Images/Game/game7.png";


const titleGoldLeft = 'https://fs.cdnxn.com/lsm99queen-next/title-gold-left.png'
const titleGoldRight = 'https://fs.cdnxn.com/lsm99queen-next/title-gold-right.png'

// Swiper
const imgSwiper1 = "https://fs.cdnxn.com/lsm99queen-next/promotion-landing/promo-1.png";
const imgSwiper2 = "https://fs.cdnxn.com/lsm99queen-next/promotion-landing/promo-2.png";
const imgSwiper3 = "https://fs.cdnxn.com/lsm99queen-next/promotion-landing/promo-3.png";
const imgSwiper4 = "https://fs.cdnxn.com/lsm99queen-next/promotion-landing/promo-4.png";
const imgSwiper5 = "https://fs.cdnxn.com/lsm99queen-next/promotion-landing/promo-5.png";
const imgSwiper6 = "https://fs.cdnxn.com/lsm99queen-next/promotion-landing/promo-6.png";
const imgSwiper7 = "https://fs.cdnxn.com/lsm99queen-next/promotion-landing/promo-7.png";
const imgSwiper8 = "https://fs.cdnxn.com/lsm99queen-next/promotion-landing/promo-8.png";
const imgSwiper9 = "https://fs.cdnxn.com/lsm99queen-next/promotion-landing/promo-9.png";
const imgSwiper10 = "https://fs.cdnxn.com/lsm99queen-next/promotion-landing/promo-10.png";
const imgSwiper11 = "https://fs.cdnxn.com/lsm99queen-next/promotion-landing/promo-11.png";
const imgSwiper12 = "https://fs.cdnxn.com/lsm99queen-next/promotion-landing/promo-12.png";
const imgSwiper13 = "https://fs.cdnxn.com/lsm99queen-next/promotion-landing/promo-13.png";
const imgSwiper14 = "https://fs.cdnxn.com/lsm99queen-next/promotion-landing/promo-14.png";

const logoLoginGif = "https://fs.cdnxn.com/lsm99queen-next/loading-login.gif";


function Index() {

	const [loginDetailModal, setLoginDetailModal] = useState(false);
	const [thumbsSwiper, setThumbsSwiper] = useState(null);
	// const [thumbsSwiper2, setThumbsSwiper2] = useState(null);
	const [menuSelected, setMenuSelected] = useState("home");
	const [gameCategorySelected, setGameCategorySelected] = useState("hit");

	const [linkLogin, setLinkLogin] = useState("https://gamev2.lsm99queen.bet/login");
	const [linkRegister, setRegister] = useState("https://gamev2.lsm99queen.bet/register?partner=947&channel=xbgqueen");

  const [close, setClose] = useState(true);

  const Gamelist = [
    { game: Game1 },
    { game: Game2 },
    { game: Game3 },
    { game: Game4 },
    { game: Game5 },
    { game: Game6 },
    { game: Game7 },
  ];
  const images = [
    'https://fs.cdnxn.com/lsm99queen-next/promotion-landing/promo-1.png',
    'https://fs.cdnxn.com/lsm99queen-next/promotion-landing/promo-2.png',
    'https://fs.cdnxn.com/lsm99queen-next/promotion-landing/promo-3.png',
    'https://fs.cdnxn.com/lsm99queen-next/promotion-landing/promo-4.png',
    'https://fs.cdnxn.com/lsm99queen-next/promotion-landing/promo-5.png',
  ];

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 10,
    },
    desktop1: {
      breakpoint: { max: 3000, min: 1320 },
      items: 8,
    },
    desktop2: {
      breakpoint: { max: 1320, min: 720 },
      items: 6,
    },
    tablet1: {
      breakpoint: { max: 960, min: 720 },
      items: 4,
    },
    tablet2: {
      breakpoint: { max: 720, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };


	const handlePromotionDetail = () => {
		setLoginDetailModal(!loginDetailModal);
	};

	const toggleLoginModal = () => setLoginDetailModal(!loginDetailModal);

	const handleLoginDetailClose = () => {
		setLoginDetailModal(false);
	};

	const handleChangeMenu = (menu) => {
		setMenuSelected(menu);
	};

	const handleChangeGames = (category) => {
		setGameCategorySelected(category);
	};

  return (
    <>
      {/* <div className="home-image home-footer-section">
        <HomeHeaderNav />
        <div className="mb">
          {close === true ? (
            <>
              <div className="alert-home">
                <div className="alert-marquee">
                  <Alert
                    message={
                      <Marquee pauseOnHover gradient={false} speed={70}>
                        ยินดีต้อนรับเข้าสู่ LSM99QUEEN เว็บออนไลน์อันดับ 1 ในไทย
                        มีทั้งบอล มวย หวย สล๊อต คาสิโน และ อื่นๆอีกมากมาย
                        เว็บตรง มั่นคง ปลอดภัย ฝาก-ถอน อัตโนมัติ
                        แอดมินคอยให้บริการลูกค้าตลอด 24 ชั่วโมง
                      </Marquee>
                    }
                  />
                  <img
                    src={BtnClose}
                    onClick={() => setClose(false)}
                    className="icon-close"
                    alt="..."
                  />
                </div>
              </div>
            </>
          ) : null}
        </div>
        <Row justify="center" align="top" className="home-section">
          <Col xs={20} lg={9}>
            <CarouselCard />
          </Col>
          <div className="mb my-2 mx-3">
            <div className="d-flex justify-content-center align-items-center ">
              <a href="https://game.lsm99queen.com/register" className="me-3">
                <img
                  src={Singin}
                  className={css`width:100%;
                        }`}
                  alt="..."
                ></img>
              </a>
              <a href="https://game.lsm99queen.bet/login ">
                <img
                  src={Login}
                  className={css`width:100%;
                        }`}
                  alt="..."
                ></img>
              </a>
            </div>
          </div>
          <Col xs={24} lg={9}>
            <ContentMenu />
          </Col>
        </Row>
        <div className="pc home-section">
          <div className="box-center">
            <div className="pc box-img-head">
              <img src={menuGameHot} className="img-100" alt="..." />
            </div>
          </div>
          <div className="carousel-game">
            <Carousel draggable={true} responsive={responsive}>
              {Gamelist.map((item) => (
                <a
                  href="https://game.lsm99queen.bet/login "
                  className="d-flex justify-content-center carousel-card"
                >
                  <img
                    src={item.game}
                    className="image-carousel-game"
                    alt="..."
                  ></img>
                </a>
              ))}
            </Carousel>
          </div>
          <a href="https://game.lsm99queen.bet/login " className="box-center">
            <img
              src={menuLookAll}
              className={css`
                width: 25%;
                &:hover,
                :focus {
                  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
                  transform: translateY(-0.5em);
                }
              `}
              alt="..."
            />
          </a>
        </div>
        <div className="mb">
          <HomeFooter />
        </div>
      </div> */}


      <div className={`login-container-bg ${menuSelected}`}>
				<div className="login-container container">
					<div className="login-wrapper">
						<div className="box-menu flex">
							<div className="menu flex">
								<a
									href="#"
									className={classNames("btn-home sub-menu", {
										active: menuSelected === "home",
									})}
									onClick={() => handleChangeMenu("home")}
								>
									<div className="text">หน้าหลัก</div>
								</a>
								<a
									href="#"
									className={classNames(
										"btn-hot-games sub-menu",
										{
											active: menuSelected === "game",
										}
									)}
									onClick={() => handleChangeMenu("game")}
								>
									<div className="text">
										ค่ายเกม
										<span className="pc-menu">ยอดนิยม</span>
									</div>
								</a>
								<a
									href="#"
									className={classNames(
										"btn-promotion sub-menu",
										{
											active:
												menuSelected === "promotion",
										}
									)}
									onClick={() =>
										handleChangeMenu("promotion")
									}
								>
									<div className="text">โปรโมชั่น</div>
								</a>
								<a
									href="#"
									className={classNames(
										"btn-affiliate sub-menu",
										{
											active:
												menuSelected === "affiliate",
										}
									)}
									onClick={() =>
										handleChangeMenu("affiliate")
									}
								>
									<div className="text">สร้างรายได้</div>
								</a>
							</div>

							<div className="login-register flex">
								<a
									href={linkLogin}
									className="shadow-login btn-login"
								>
									<div className="text">
										<span className="text-gold">
											เข้าสู่ระบบ
										</span>
									</div>
								</a>
								<a href={linkRegister} className="btn-register">
									<div className="text">
										<span>สมัครสมาชิก</span>
									</div>
								</a>
							</div>
						</div>

						{/* หน้าหลัก */}
						<div
							className={`${
								menuSelected === "home"
									? menuSelected
									: "hidden"
							}`}
						>
							<div className="flex">
								<div className="left">
									<a href={linkLogin} className="login-logo">
										<img src="https://fs.cdnxn.com/lsm99queen-next/appbar-queen.png" alt="appbar Queen Image" />
									</a>
								</div>
								<div className="right">
									<a href={linkLogin} className="login-logo">
										<div className="logo-login-right">
											<img
												className="m-auto logo-login-right-gif"
												src={logoLoginGif}
												alt="Label Login"
												draggable="false"
											/>
										</div>
									</a>
									<div className="landing-detail--">
										<div className="landing-detail">
											<p>
												ยินดีต้อนรับสู่เว็บไซต์เดิมพันออนไลน์
												ยอดนิยมของเมืองไทย
												<br />
												เราคือผู้ให้บริการเว็บพนัน
												LSM99Queen เว็บไซต์ยอดฮิต
												<br />
												ด้วยบริการที่หลากหลายตอบโจทย์ผู้ใช้งาน
												รวมทุกพนันไว้ ในเว็บเดียว
												<br />
												แทงบอลออนไลน์ คาสิโนสด
												ส่งตรงจากบ่อนมาตรฐาน
												เกมสล็อตภาพสวย
												<br /> แทงหวย บนมือถือ
												พร้อมเกมแนวใหม่อย่างอี-สปอร์ตรวมเว็บชั้น
												<br />
												นำที่เปิดให้บริการทั้ง SA
												gaming, Sexy gaming และอื่นๆ
												อีกมากมาย
												<br />
												บริการด้วยระบบออนไลน์มาตรฐานสากล
												เท่าไหร่ก็จ่าย มั่นใจหายห่วง
												<br />
												ปลอดภัยไร้กังวล
												การันตีด้วยจำนวนสมาชิกมากที่สุด
												ณ ขณะนี้
												<br />
											</p>
										</div>
									</div>

									<div className="box-group-landing">
										<span className="pc">
											<a
												href={linkLogin}
												className="btn-login-bottom"
												onClick={() =>
													handlePromotionDetail()
												}
											>
												<div className="text text-gold">
													เข้าสู่ระบบ
												</div>
											</a>
										</span>
										<span className="pc">
											<a
												href={linkRegister}
												className="btn-register-bottom"
											>
												<div className="text">
													สมัครสมาชิก
												</div>
											</a>
										</span>
									</div>
								</div>
							</div>
						</div>

						{/* ค่าบเกมยอดนิยม */}
						<div
							className={`game-landing-section ${
								menuSelected === "game"
									? menuSelected
									: "hidden"
							}`}
						>
							<div className="games-select">
								<div
									className={classNames("game hit", {
										active: gameCategorySelected === "hit",
									})}
									onClick={() => handleChangeGames("hit")}
								>
									<div className="img"></div>
									<div className="title">
										<span className="">ยอดนิยม</span>
									</div>
								</div>
								<div
									className={classNames("game sport", {
										active:
											gameCategorySelected === "sport",
									})}
									onClick={() => handleChangeGames("sport")}
								>
									<div className="img"></div>
									<div className="title">
										<span className="">กีฬา</span>
									</div>
								</div>
								<div
									className={classNames("game casino", {
										active:
											gameCategorySelected === "casino",
									})}
									onClick={() => handleChangeGames("casino")}
								>
									<div className="img"></div>
									<div className="title">
										<span className="">คาสิโน</span>
									</div>
								</div>
								<div
									className={classNames("game lotto", {
										active:
											gameCategorySelected === "lotto",
									})}
									onClick={() => handleChangeGames("lotto")}
								>
									<div className="img"></div>
									<div className="title">
										<span className="">หวย</span>
									</div>
								</div>
								<div
									className={classNames("game slot", {
										active: gameCategorySelected === "slot",
									})}
									onClick={() => handleChangeGames("slot")}
								>
									<div className="img"></div>
									<div className="title">
										<span className="">สล็อต</span>
									</div>
								</div>
							</div>

							<div className="games-list-section">
								<div
									className={` ${
										gameCategorySelected === "hit"
											? ""
											: "hidden"
									}`}
								>
									<div className="box-game-login">
									<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/sexy.png"
												alt="sexy"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/pg.png"
												alt="pg"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/sa.png"
												alt="sa"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/sportlsm.png"
												alt="sportlsm"
												className="img-games"
												draggable="false"
											/>
										</div>
										
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/pp.png"
												alt="pp"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/mylotto99.png"
												alt="mylotto"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/dg.png"
												alt="dg"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/muaypukyok.png"
												alt="muay puk yok"
												className="img-games"
												draggable="false"
											/>
										</div>
									</div>
								</div>
								<div
									className={` ${
										gameCategorySelected === "sport"
											? ""
											: "hidden"
									}`}
								>
									<div className="box-game-login">
									
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/sportlsm.png"
												alt="sportlsm"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/muaypukyok.png"
												alt="mpy"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/threesing.png"
												alt="sing"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/sabasport.png"
												alt="saba"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/sbo.png"
												alt="sbobet"
												className="img-games"
												draggable="false"
											/>
										</div>																																								
									</div>
								</div>
								<div
									className={` ${
										gameCategorySelected === "casino"
											? ""
											: "hidden"
									}`}
								>
									<div className="box-game-login">
									<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/yeebet.png"
												alt="yeebet"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/wcasino.png"
												alt="w"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/evolution.png"
												alt="evolution"
												className="img-games"
												draggable="false"
											/>
										</div>
										
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/ab.png"
												alt="allbet"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/sexy.png"
												alt="sexy"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/wm.png"
												alt="wm"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/sa.png"
												alt="sa"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/dg.png"
												alt="dg"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/pretty.png"
												alt="pretty"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/playtech.png"
												alt="playtech"
												className="img-games"
												draggable="false"
											/>
										</div>
										
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/ebet.png"
												alt="ebet"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/pp.png"
												alt="pp"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/sw.png"
												alt="skywind"
												className="img-games"
												draggable="false"
											/>
										</div>
										
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/bg.png"
												alt="bg"
												className="img-games"
												draggable="false"
											/>
										</div>
										
									</div>
								</div>
								<div
									className={` ${
										gameCategorySelected === "lotto"
											? ""
											: "hidden"
									}`}
								>
									<div className="box-game-login">
									
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/luckylotto.png"
												alt="lottoexit"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/mylotto99.png"
												alt="mylotto"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/qqkeno.png"
												alt="qq"
												className="img-games"
												draggable="false"
											/>
										</div>
									</div>
								</div>

								<div
									className={` ${
										gameCategorySelected === "slot"
											? ""
											: "hidden"
									}`}
								>
									<div className="box-game-login">
									<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/fastspin.png"
												alt="fastspin"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/megawin.png"
												alt="megawin"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/jili.png"
												alt="jili"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/bng.png"
												alt="bng"
												className="img-games"
												draggable="false"
											/>
										</div>
										
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/btgaming.png"
												alt="bt"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/expanse.png"
												alt="expanse"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/ps.png"
												alt="playstar"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/nextspin.png"
												alt="nextspin"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/fg.png"
												alt="spadegaming"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/jk.png"
												alt="joker"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/mg.png"
												alt="mg"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/haba.png"
												alt="haba"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/gpi.png"
												alt="gp"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/spsg.png"
												alt="simpleplay"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/gw.png"
												alt="gw"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/kingmaker.png"
												alt="kingmaker"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/evoplay.png"
												alt="evo"
												className="img-games"
												draggable="false"
											/>
										</div>
										
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/ka.png"
												alt="ka"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/pg.png"
												alt="pg"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/playtech.png"
												alt="playtech"
												className="img-games"
												draggable="false"
											/>
										</div>
										
										
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/mp.png"
												alt="mp"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/pp.png"
												alt="pp"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/sw.png"
												alt="skywind"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/ADVANTPLAY.png"
												alt="ap"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/rich.png"
												alt="rich88"
												className="img-games"
												draggable="false"
											/>
										</div>
										<div
											className="games-box-img"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<img
												src="https://fs.cdnxn.com/lsm99queen-next/brand/naga.png"
												alt="naga"
												className="img-games"
												draggable="false"
											/>
										</div>
										
									</div>
								</div>
							</div>
						</div>

						{/* Promotion */}
						<div
							className={`${
								menuSelected === "promotion"
									? menuSelected
									: "hidden"
							}`}
						>
							<span className="pc">
								<div className="slide-menu">
									<Swiper
										loop={true}
										spaceBetween={10}
										navigation={true}
										thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
										modules={[
											FreeMode,
											Navigation,
											Thumbs,
											// Autoplay,
										]}
										className="mySwiper2"
										// autoplay={{
										// 	delay: 2000,
										// 	disableOnInteraction: false,
										// }}
									>
										<SwiperSlide className="swiper-main-section">
											<img
												src={imgSwiper1}
												alt="img demo"
												className="swiper-main-image"
											/>
										</SwiperSlide>
										<SwiperSlide className="swiper-main-section">
											<img
												src={imgSwiper2}
												alt="img demo"
												className="swiper-main-image"
											/>
										</SwiperSlide>
										<SwiperSlide className="swiper-main-section">
											<img
												src={imgSwiper3}
												alt="img demo"
												className="swiper-main-image"
											/>
										</SwiperSlide>
										<SwiperSlide className="swiper-main-section">
											<img
												src={imgSwiper4}
												alt="img demo"
												className="swiper-main-image"
											/>
										</SwiperSlide>
										<SwiperSlide className="swiper-main-section">
											<img
												src={imgSwiper5}
												alt="img demo"
												className="swiper-main-image"
											/>
										</SwiperSlide>
										<SwiperSlide className="swiper-main-section">
											<img
												src={imgSwiper6}
												alt="img demo"
												className="swiper-main-image"
											/>
										</SwiperSlide>
										<SwiperSlide className="swiper-main-section">
											<img
												src={imgSwiper7}
												alt="img demo"
												className="swiper-main-image"
											/>
										</SwiperSlide>
										<SwiperSlide className="swiper-main-section">
											<img
												src={imgSwiper8}
												alt="img demo"
												className="swiper-main-image"
											/>
										</SwiperSlide>
										<SwiperSlide className="swiper-main-section">
											<img
												src={imgSwiper9}
												alt="img demo"
												className="swiper-main-image"
											/>
										</SwiperSlide>
										<SwiperSlide className="swiper-main-section">
											<img
												src={imgSwiper10}
												alt="img demo"
												className="swiper-main-image"
											/>
										</SwiperSlide>
										<SwiperSlide className="swiper-main-section">
											<img
												src={imgSwiper11}
												alt="img demo"
												className="swiper-main-image"
											/>
										</SwiperSlide>
										<SwiperSlide className="swiper-main-section">
											<img
												src={imgSwiper12}
												alt="img demo"
												className="swiper-main-image"
											/>
										</SwiperSlide>
										<SwiperSlide className="swiper-main-section">
											<img
												src={imgSwiper13}
												alt="img demo"
												className="swiper-main-image"
											/>
										</SwiperSlide>
										<SwiperSlide className="swiper-main-section">
											<img
												src={imgSwiper14}
												alt="img demo"
												className="swiper-main-image"
											/>
										</SwiperSlide>
									</Swiper>

									<Swiper
										onSwiper={setThumbsSwiper}
										loop={true}
										spaceBetween={10}
										slidesPerView={4}
										freeMode={true}
										watchSlidesProgress={true}
										autoplay={{
											delay: 2000,
											disableOnInteraction: false,
										}}
										modules={[
											FreeMode,
											Navigation,
											Thumbs,
											Autoplay,
										]}
										className="mySwiper mySwiperThumb"
									>
										<SwiperSlide>
											<img
												src={imgSwiper1}
												alt="img demo"
											/>
										</SwiperSlide>
										<SwiperSlide>
											<img
												src={imgSwiper2}
												alt="img demo"
											/>
										</SwiperSlide>
										<SwiperSlide>
											<img
												src={imgSwiper3}
												alt="img demo"
											/>
										</SwiperSlide>
										<SwiperSlide>
											<img
												src={imgSwiper4}
												alt="img demo"
											/>
										</SwiperSlide>
										<SwiperSlide>
											<img
												src={imgSwiper5}
												alt="img demo"
											/>
										</SwiperSlide>
										<SwiperSlide>
											<img
												src={imgSwiper6}
												alt="img demo"
											/>
										</SwiperSlide>
										<SwiperSlide>
											<img
												src={imgSwiper7}
												alt="img demo"
											/>
										</SwiperSlide>
										<SwiperSlide>
											<img
												src={imgSwiper8}
												alt="img demo"
											/>
										</SwiperSlide>
										<SwiperSlide>
											<img
												src={imgSwiper9}
												alt="img demo"
											/>
										</SwiperSlide>
										<SwiperSlide>
											<img
												src={imgSwiper10}
												alt="img demo"
											/>
										</SwiperSlide>
										<SwiperSlide>
											<img
												src={imgSwiper11}
												alt="img demo"
											/>
										</SwiperSlide>
										<SwiperSlide>
											<img
												src={imgSwiper12}
												alt="img demo"
											/>
										</SwiperSlide>
										<SwiperSlide>
											<img
												src={imgSwiper13}
												alt="img demo"
											/>
										</SwiperSlide>
										<SwiperSlide>
											<img
												src={imgSwiper14}
												alt="img demo"
											/>
										</SwiperSlide>
									</Swiper>
								</div>

								<div className="box-group-landing">
									<span className="pc">
										<a
											href={linkLogin}
											className="btn-login-bottom"
											onClick={() =>
												handlePromotionDetail()
											}
										>
											<div className="text text-gold">
												เข้าสู่ระบบ
											</div>
										</a>
									</span>
									<span className="pc">
										<a href={linkRegister} className="btn-register-bottom">
											<div className="text">
												สมัครสมาชิก
											</div>
										</a>
									</span>
								</div>
							</span>
							<span className="mb">
								<div className="slide-menu-mb">
									<Swiper
										loop={true}
										spaceBetween={5}
										navigation={true}
										slidesPerView={4}
										modules={[
											FreeMode,
											Navigation,
											Thumbs,
											// Autoplay
										]}
										// autoplay={{
										// 	delay: 2000,
										// 	disableOnInteraction: false,
										// }}
										className="mySwiperMb2"
										direction={"vertical"}
									>
										<SwiperSlide className="swiper-main-section-mb">
											<img
												src={imgSwiper1}
												alt="img demo"
												className="swiper-main-image-mb"
											/>
										</SwiperSlide>
										<SwiperSlide className="swiper-main-section-mb">
											<img
												src={imgSwiper2}
												alt="img demo"
												className="swiper-main-image-mb"
											/>
										</SwiperSlide>
										<SwiperSlide className="swiper-main-section-mb">
											<img
												src={imgSwiper3}
												alt="img demo"
												className="swiper-main-image-mb"
											/>
										</SwiperSlide>
										<SwiperSlide className="swiper-main-section-mb">
											<img
												src={imgSwiper4}
												alt="img demo"
												className="swiper-main-image-mb"
											/>
										</SwiperSlide>
										<SwiperSlide className="swiper-main-section-mb">
											<img
												src={imgSwiper5}
												alt="img demo"
												className="swiper-main-image-mb"
											/>
										</SwiperSlide>
										{/* <SwiperSlide className="swiper-main-section-mb">
											<img
												src={imgSwiper6}
												alt="img demo"
												className="swiper-main-image-mb"
											/>
										</SwiperSlide>
										<SwiperSlide className="swiper-main-section-mb">
											<img
												src={imgSwiper7}
												alt="img demo"
												className="swiper-main-image-mb"
											/>
										</SwiperSlide>
										<SwiperSlide className="swiper-main-section-mb">
											<img
												src={imgSwiper8}
												alt="img demo"
												className="swiper-main-image-mb"
											/>
										</SwiperSlide>
										<SwiperSlide className="swiper-main-section-mb">
											<img
												src={imgSwiper9}
												alt="img demo"
												className="swiper-main-image-mb"
											/>
										</SwiperSlide>
										<SwiperSlide className="swiper-main-section-mb">
											<img
												src={imgSwiper10}
												alt="img demo"
												className="swiper-main-image-mb"
											/>
										</SwiperSlide>
										<SwiperSlide className="swiper-main-section-mb">
											<img
												src={imgSwiper11}
												alt="img demo"
												className="swiper-main-image-mb"
											/>
										</SwiperSlide>
										<SwiperSlide className="swiper-main-section-mb">
											<img
												src={imgSwiper12}
												alt="img demo"
												className="swiper-main-image-mb"
											/>
										</SwiperSlide>
										<SwiperSlide className="swiper-main-section-mb">
											<img
												src={imgSwiper13}
												alt="img demo"
												className="swiper-main-image-mb"
											/>
										</SwiperSlide>
										<SwiperSlide className="swiper-main-section-mb">
											<img
												src={imgSwiper14}
												alt="img demo"
												className="swiper-main-image-mb"
											/>
										</SwiperSlide> */}
									</Swiper>
								</div>
							</span>
						</div>

						{/* Affiliate */}
						<div className={`${menuSelected === "affiliate" ? menuSelected : "hidden"}`}>
							<div className="affiliate-section">
								<div className="affiliate-left">
									<div className='title-gold-img login-page'>
										<img src={titleGoldLeft} className='title-gold-left' alt="title gold" />
										<div>
											<h4 className='mb-0'>
												<span className='text-gold'>แค่แชร์ลิ้งค์ก็สร้างรายได้</span>
											</h4>
											<h5 className="sub-title-gold-pattern">แนะนำเพื่อนสร้างรายได้ 100,000 / สัปดาห์</h5>
										</div>
										<img src={titleGoldRight} className='title-gold-right' alt="title gold" />
									</div>
									<img
										src="https://fs.cdnxn.com/lsm99queen-next/affiliate-img.png"
										alt="Label affiliate images"
										draggable="false"
										style={{
											margin: ".5rem auto",
										}}
										width={560}
										height={420}
									/>

									<span className="pc">
										<div className="login-register-section">
											<a href={linkLogin}
												className="btn-login-bottom"
												onClick={() => handlePromotionDetail()}
											>
												<div className="text text-gold">
													เข้าสู่ระบบ
												</div>
											</a>
											<a href={linkRegister} className="btn-register-bottom">
												<div className="text">
													สมัครสมาชิก
												</div>
											</a>
										</div>
									</span>
								</div>
								<div className="affiliate-right">
									<img
										src="https://fs.cdnxn.com/lsm99queen-next/affiliate-detail.png"
										alt="Detail affiliate right"
										draggable="false"
										style={{
											margin: ".5rem auto",
										}}
										width={584}
										height={644}
									/>
								</div>
							</div>
						</div>
            
						{/* <Modal
							className={`login-modal ${
								forgotPasswordlModal === true
									? "is-open-forgot-password "
									: ""
							}`}
							show={loginDetailModal}
							onToggle={toggleLoginModal}
							centered
						> */}
							<>
								{/* <Image
									className="btn-back"
									src={labelBack}
									alt="Label Login"
									draggable="false"
									onClick={() => handleLoginDetailClose()}
								/>

								<form
									className="login-form"
									onSubmit={loginHandleSubmit(onLogin)}
								>
									<Image
										className="mb-1 logo testKwannie"
										src={logoLogin}
										alt="Label Login"
										draggable="false"
										width="100"
										height="100"
									/>

									<div className="input-section">
										<Image
											src={labelLogin}
											alt="Label Login"
											style={{
												width: "179px",
												height: "44px",
												margin: "0 auto 1rem",
											}}
										/>
										<div className="custom-input">
											<div className="login-input-username">
												<span
													className={`img-input-user ${
														loginErrors.username
															?.message
															? "error"
															: ""
													}`}
												></span>
												<FormInput
													control={loginControl}
													invalid={
														loginErrors.username
													}
													name="username"
													className="form-control"
													placeholder="ยูซเซอร์เนม / หมายเลขโทรศัพท์"
													autoComplete="true"
												/>
											</div>
											<div className="feedback-">
												<FormFeedback>
													{
														loginErrors.username
															?.message
													}
													{errorMessage}
												</FormFeedback>
											</div>
										</div>

										<div className="custom-input">
											<div className="login-input-password">
												<span
													className={`img-input-pass ${
														loginErrors.password
															?.message
															? "error"
															: ""
													}`}
												></span>
												<FormInput
													control={loginControl}
													invalid={
														loginErrors.password
													}
													name="password"
													className="form-control"
													placeholder="รหัสผ่าน"
													autoComplete="true"
													type={
														inputTypePassword ===
														true
															? "password"
															: "text"
													}
												/>
												<span
													className="input-password-toggle"
													onClick={() =>
														handleToggleTypePassword()
													}
												>
													<Image
														src="https://fs.cdnxn.com/lsm99queen-next/icons/eye-open.png"
														width={24}
														height={24}
														alt="show password"
														className={`open-password ${
															inputTypePassword ===
															true
																? ""
																: "hidden"
														}`}
													/>
													<Image
														src="https://fs.cdnxn.com/lsm99queen-next/icons/eye-crossed.png"
														width={24}
														height={24}
														alt="close password"
														className={`close-password ${
															inputTypePassword ===
															true
																? "hidden"
																: ""
														}`}
													/>
												</span>
											</div>
											<div className="feedback-">
												<FormFeedback>
													{
														loginErrors.password
															?.message
													}
												</FormFeedback>
											</div>
										</div>

										<Link
											href="#"
											onClick={() => handleOpenModal()}
											className="text-light-yellow text-reset-password f-weight-300 text-underline my-2"
										>
											ลืมรหัสผ่าน?
										</Link>
										<br />

										<button
											type="submit"
											className="w-btn-cover btn-submit-login text-dark-red mt-3 mb-2"
										>
											เข้าสู่ระบบ
										</button>

										<div className="f-weight-300 my-3 text-light-gray">
											ยังไม่ได้เป็นสมาชิกกับ LSM99QUEEN ?
										</div>

										<a
											href={`/register${parameterAffiliate ? '?' + parameterAffiliate : ''}`}
											className="btn-register-login"
										>
											<span className="text-gold ">
												สมัครสมาชิก
											</span>
										</a>
									</div>
								</form> */}


								{/* <div className='login-bottom-change-lang'>

									<ChangeLanguage />
									<div className="login-form login-bottom w-box">
										<div className="f-weight-300 my-2 text-light-gray">
											ติดต่อเราได้ตลอด 24 ชั่วโมง
										</div>

										<div className='login-btns'>
											<a href={isSettings && settings.data.link_line || ''} className='btn-line' target='_blank'>
												<Image
													src={lineLogo}
													alt="Contact Line"
													style={{
														width: "64px",
														height: "64px",
														margin: "0 auto .5rem",
													}}
												/>
											</a>

											<a href={isSettings && settings.data.link_telegram || ''} className='btn-telegram' target='_blank'>
												<img
													src="https://fs.cdnxn.com/lsm99queen-next/tg-logo.png"
													alt="Contact Telegram"
													style={{
														width: "64px",
														height: "64px",
														margin: "0 auto .5rem",
													}}
													width={64}
													height={64}
												/>
											</a>
										</div>
									</div>
								</div> */}
							</>
						{/* </Modal> */}

					</div>
				</div>
			</div>

			<div className="w-bottom-nav-mb">
				<div className="login-register-section">
					<div className="login-register-section---">
						<a
							href={linkLogin}
							className="btn-login-bottom btn-login---"
						>
							<div className="text text-gold">เข้าสู่ระบบ</div>
						</a>
						<a
							href={linkRegister}
							className="btn-register-bottom btn-regis---"
						>
							<div className="text">สมัครสมาชิก</div>
						</a>
					</div>
				</div>
			</div>
    </>
  );
}

export default Index;
